export const universities = [
  { label: "Universidad Peruana de Ciencias Aplicadas", value: 1 },
  { label: "Universidad San Ignacio de Loyola", value: 2 },
  { label: "Universidad Pacífico", value: 3 },
  { label: "Universidad Privada del Norte", value: 4 },
  { label: "Universidad Nacional Mayor de San Marcos", value: 5 },
  { label: "Universidad Nacional Federico Villareal", value: 6 },
  { label: "Universidad Peruana Cayetano Heredia", value: 7 },
  { label: "Universidad Nacional de Trujillo", value: 8 },
  { label: "Universidad Nacional de Piura", value: 9 },
  { label: "Universidad Ricardo Palma", value: 10 },
  { label: "Universidad de San Martín de Porres", value: 11 },
  { label: "Universidad Científica del Sur", value: 12 },
  { label: "Universidad Nacional San Luis Gonzaga de Ica", value: 13 },
  { label: "Universidad Nacional de San Agustín", value: 14 },
  { label: "Universidad Nacional de San Antonio Abad del Cusco", value: 15 },
  { label: "Universidad César Vallejo", value: 16 },
  { label: "Universidad Nacional José Faustino Sánchez Carrión", value: 17 },
  { label: "Universidad Nacional Pedro Ruiz Gallo", value: 18 },
  { label: "Universidad Privada Norbert Wiener", value: 19 },
  { label: "Universidad Católica de Santa María", value: 20 },
  { label: "Universidad Nacional del Centro del Perú", value: 21 },
  { label: "Universidad Privada Antenor Orrego", value: 22 },
  { label: "Universidad Privada San Pedro", value: 23 },
  { label: "Universidad Peruana de los Andes", value: 24 },
  { label: "Universidad Nacional de la Amazonía Peruana", value: 25 },
  { label: "Universidad Nacional del Altiplano", value: 26 },
  { label: "Universidad Privada de Tacna", value: 27 },
  { label: "Universidad Nacional de Cajamarca", value: 28 },
  { label: "Universidad Nacional Daniel Alcides Carrión", value: 29 },
];
